<template>
  <div>
    <!-- Hamburger Icon -->
    <input type="checkbox" id="menu-toggle" class="menu-toggle">
    <label for="menu-toggle" class="hamburger">
      <span></span>
      <span></span>
      <span></span>
    </label>

    <!-- Sidebar -->
    <nav class="sidemenu">
      <div class="topMenu-wrapper">
        <router-link to="/" class="topMenu-brand">
          <img style="height: 100px;" src="@/assets/logo.svg" alt="Logo" />
        </router-link>
      </div>
      <ul class="inner-menu">
        <li v-for="item in menuItems" :key="item.name">
          <router-link :to="item.path">
            <i :class="item.icon"></i> {{ item.name }}
          </router-link>
        </li>
      </ul>
    </nav>
  </div>
</template>

<script>
export default {
  name: 'SideMenu',
  props: {
    menuItems: {
      type: Array,
      default: () => [
        { name: 'Dashboard', path: '/admin/dashboard', icon: 'ni ni-tv-2' },
        { name: 'Scan StiQR', path: '/admin/scanadv', icon: 'ni ni-camera-compact' },
        { name: 'My StiQRs', path: '/admin/mystiqrs', icon: 'ni ni-bullet-list-67' },
        { name: 'My StiQRs Admin', path: '/admin/mystiqrsAdmin', icon: 'ni ni-bullet-list-67' },
        { name: 'Generate StiQR', path: '/admin/generateqr', icon: 'ni ni-atom' },
        { name: 'Print StiQR', path: '/admin/printqr', icon: 'ni ni-image' }
      ]
    }
  }
}
</script>

<style scoped>
.sidemenu {
  background-color: white;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
  padding: 20px;
  top: 0;
  left: 0;
  overflow-y: auto;
  z-index: 100;
  height: 100vh;
  width: 250px;
  /* Fixed width */
}

.inner-menu {
  list-style: none;
  padding: 1rem;
  margin: 0;
  display: grid;
  justify-content: center;
}

.logo-img {
  height: 50px;
}

.inner-menu li {
  margin-bottom: 15px;
}

.inner-menu a {
  text-decoration: none;
  display: flex;
  align-items: center;
  color: #3f4066;
  font-weight: bold;
  transition: color 0.3s ease;
}

.inner-menu a:hover {
  color: #87cafc;
}

.inner-menu i {
  margin-right: 10px;
}

/* Hamburger Menu Styling */
.hamburger {
  cursor: pointer;
  display: none;
  flex-direction: column;
  gap: 5px;
  padding: 15px;
}

.hamburger span {
  background-color: #3f4066;
  border-radius: 2px;
  height: 3px;
  width: 30px;
}

/* Hide the checkbox by default */
.menu-toggle {
  display: none;
}

@media (max-width: 768px) {

  /* Show hamburger only on mobile */
  .hamburger {
    display: flex;
    position: fixed;
    top: 10px;
    left: 15px;
    z-index: 102;
  }

  .sidemenu {
    position: fixed;
    top: 0;
    left: -250px;
    width: 250px;
    height: 100%;
    background-color: white;
    padding: 20px;
    transition: left 0.3s ease;
    z-index: 100;
  }

  .menu-toggle:checked+.hamburger+.sidemenu {
    left: 0;
  }

  .main-content {
    padding-left: 0;
    /* Remove the left padding on mobile */
  }
}

@media (min-width: 769px) {

  /* Hide hamburger and make sidebar static on desktop */
  .hamburger {
    display: none;
  }

  .sidemenu {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 250px;
  }
}
</style>
