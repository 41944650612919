<template>
  <nav class="custom-menu">
    <div class="custom-menu-content">
      <!-- Display the current route name as the title on the left side -->
      <div class="custom-menu-left">
        <!-- Search icon or expanded search field based on the screen size -->
        <div class="custom-search-container" @click="toggleSearch">
          <i class="fas fa-search custom-search-icon"></i>
          <input v-show="showSearchField" type="text" placeholder="Search" v-model="searchQuery"
            @keyup.enter="performSearch" />
        </div>
      </div>

      <!-- User profile dropdown menu on the right -->
      <div class="custom-menu-right">
        <div class="custom-profile-dropdown" @click="toggleDropdown">
          <img src="@/assets/favicon.png" class="custom-avatar" />
          <!-- Hidden on mobile -->
          <span class="custom-username" v-if="!isMobile">{{ fullName }}</span>
        </div>

        <!-- Dropdown menu for user actions -->
        <ul :class="{ 'custom-dropdown-menu': true, 'visible': showDropdown }">
          <li class="custom-dropdown-header">WELCOME!</li>
          <li><router-link to="/profile">My profile</router-link></li>
          <li><router-link to="/settings">Settings</router-link></li>
          <li><router-link to="/activity">Activity</router-link></li>
          <li><router-link to="/support">Support</router-link></li>
          <li class="custom-dropdown-divider"></li>
          <li><a href="#!" @click="logout">Logout</a></li>
        </ul>
      </div>
    </div>

    <!-- Route name title, moved to the bottom for mobile -->
    <div class="custom-menu-bottom">
      <span class="custom-route-name">{{ routeName }}</span>
    </div>
  </nav>
</template>


<script>
import { ref, onMounted, onBeforeUnmount } from "vue"; // Import Vue's composition API

export default {
  name: "TopMenu",
  setup() {
    const showDropdown = ref(false);
    const showSearchField = ref(false); // Controls the visibility of the search input
    const searchQuery = ref("");
    const fullName = ref("Adrian Paul");
    const isMobile = ref(false); // Detect if the screen is mobile

    const toggleDropdown = () => {
      showDropdown.value = !showDropdown.value;
    };

    const closeDropdown = (event) => {
      if (!event.target.closest(".custom-profile-dropdown")) {
        showDropdown.value = false;
      }
    };

    const toggleSearch = () => {
      showSearchField.value = !showSearchField.value;
    };

    const performSearch = () => {
      console.log("Searching for:", searchQuery.value);
    };

    const logout = () => {
      localStorage.clear();
      window.location.href = "/login";
    };

    // Detect mobile screen width
    const detectMobile = () => {
      isMobile.value = window.innerWidth <= 768;
    };

    onMounted(() => {
      detectMobile();
      window.addEventListener("resize", detectMobile); // Update on window resize
      document.addEventListener("click", closeDropdown);
    });

    onBeforeUnmount(() => {
      window.removeEventListener("resize", detectMobile);
      document.removeEventListener("click", closeDropdown);
    });

    return {
      showDropdown,
      showSearchField,
      searchQuery,
      fullName,
      isMobile,
      toggleDropdown,
      toggleSearch,
      performSearch,
      logout,
    };
  },
  computed: {
    routeName() {
      const { name } = this.$route;
      return name ? name.charAt(0).toUpperCase() + name.slice(1) : "";
    },
  },
};
</script>

<style scoped>
.custom-menu {
  background-color: #d1e9fd;
  padding: 10px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  flex-wrap: wrap;
}

/* Content Layout */
.custom-menu-content {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

/* Positioning for Left, Center, and Right sections of the menu */
.custom-menu-left,
.custom-menu-center,
.custom-menu-right {
  display: flex;
  align-items: center;
}

/* Route name styling */
.custom-route-name {
  font-size: 24px;
  font-weight: bold;
  color: #333;
}

/* Search bar and icon styling */
.custom-search-container {
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
}

/* Search input styling */
.custom-search-container input {
  padding: 5px 10px;
  border-radius: 20px;
  border: 1px solid #ddd;
  outline: none;
  width: 200px;
}

/* Search icon styling */
.custom-search-icon {
  position: absolute;
  right: 10px;
  color: #999;
}

/* Profile dropdown container */
.custom-profile-dropdown {
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: center;
}

/* Profile info - avatar and username */
.custom-profile-info {
  display: flex;
  align-items: center;
}

/* Avatar image styling */
.custom-avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
}

/* Username next to the avatar */
.custom-username {
  font-size: 14px;
  color: #333;
}

/* Dropdown menu styling */
.custom-dropdown-menu {
  position: absolute;
  top: 60px;
  right: 0;
  width: 200px;
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  z-index: 1000;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s, visibility 0.3s;
}

/* Dropdown menu visible state */
.custom-dropdown-menu.visible {
  opacity: 1;
  visibility: visible;
}

/* Dropdown menu items */
.custom-dropdown-menu li {
  padding: 10px;
  color: #333;
  display: flex;
  align-items: center;
}

/* Hover effect for dropdown items */
.custom-dropdown-menu li:hover {
  background-color: #f0f0f0;
}

/* Dropdown header */
.custom-dropdown-header {
  font-size: 12px;
  font-weight: bold;
  color: #666;
}

/* Dropdown divider */
.custom-dropdown-divider {
  border-top: 1px solid #ddd;
  margin: 5px 0;
}

/* Hamburger menu icon */
.hamburger-menu {
  display: none;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  cursor: pointer;
}

.hamburger-menu div {
  width: 25px;
  height: 3px;
  background-color: #333;
  margin: 3px 0;
  transition: 0.4s;
}

/* Hidden checkbox for hamburger menu */
#hamburger-checkbox {
  display: none;
}

/* Menu sliding animation */
#hamburger-checkbox:checked~.sidemenu {
  left: 0;
}

#hamburger-checkbox:checked~.hamburger-menu div:nth-child(1) {
  transform: rotate(-45deg) translate(-5px, 6px);
}

#hamburger-checkbox:checked~.hamburger-menu div:nth-child(2) {
  opacity: 0;
}

#hamburger-checkbox:checked~.hamburger-menu div:nth-child(3) {
  transform: rotate(45deg) translate(-5px, -6px);
}

/* Sidebar menu styling */
.sidemenu {
  background-color: white;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
  padding: 20px;
  top: 60px;
  left: -250px;
  overflow-y: auto;
  z-index: 100;
  width: 250px;
  height: 100%;
  position: fixed;
  transition: 0.3s;
}

/* Inner menu in the sidebar */
.inner-menu {
  list-style: none;
  padding: 1rem;
  margin: 0;
  display: grid;
  justify-content: center;
}

.inner-menu li {
  margin-bottom: 15px;
}

.inner-menu a {
  text-decoration: none;
  display: flex;
  align-items: center;
  color: #3f4066;
  font-weight: bold;
  transition: color 0.3s ease;
}

/* Hover effect for sidebar links */
.inner-menu a:hover {
  color: #87cafc;
}

.inner-menu i {
  margin-right: 10px;
}

.main-content {
  flex: 1;
  padding: 20px;
  overflow-y: auto;
}

/* Search input focus behavior */
.custom-search-container input:focus {
  display: block;
}

/* Hide the username on mobile */
@media (max-width: 768px) {
  .custom-username {
    display: none;
  }

  /* Move route name to the bottom for mobile */
  .custom-menu-bottom {
    order: 1;
    width: 100%;
    text-align: center;
    margin-top: 10px;
    font-size: 18px;
  }

  .hamburger-menu {
    display: flex;
  }

  .custom-search-container input {
    display: none;
  }

  .custom-search-container input:focus {
    display: block;
    width: 150px;
  }
}
</style>
